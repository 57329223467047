form.survey-fields {

}

.survey-fields .form-group,
.survey-fields .form-check {
    margin-bottom: 1.5rem;
}
.survey-fields fieldset .form-check {
    margin-bottom:0;
}

/** Temporary **/
.survey-fields .form-group, .survey .form-check {
    /*border: 2px dashed blue;*/
}
.survey-fields .debug {
    /*font-weight:bold;*/
    /*color:green;*/
    color: gray;
    border: 1px solid gray;
    display:none;
}

.btn.disabled {
    cursor: default !important;
}