.header {
    /*background-color: #cccccc;*/
    padding: 1rem 0;

    /*background: #cedce7; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #cedce7 0%, #596a72 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #cedce7 0%,#596a72 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #cedce7 0%,#596a72 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cedce7', endColorstr='#596a72',GradientType=0 ); !* IE6-9 *!*/
    /*background: #0066cc url('../../assets/images/header.png') no-repeat top center fixed cover;*/

    /*background-attachment: fixed;*/
    background-color: rgb(35,31,32);
    text-align: center;
    position: relative;
    z-index: 1;

}

.header:before {
    content: "";
    z-index: -2;
    background-position: top center;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-color: rgb(35,31,32);
    /*background-image: url('../../assets/images/header.png'); moved to styled components*/
    background-size: cover;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0.5;

}

.header__overlay {
    opacity: 0.5;
    background-color: black;
    width: 100%;
    height: 100%;
    margin: -1rem;
    padding: 0;
    top:0;
    z-index: 0;
    position:absolute;
}

.header__logo {
    max-height: 150px;
    -webkit-animation: fadein 1s;
            animation: fadein 1s
}

.header__title {
    font-size: 28px;
    color: white;
    /*text-shadow:*/
            /*-1px -1px 1px #999,*/
            /*1px -1px 1px #999,*/
            /*-1px 1px 1px #999,*/
            /*1px 1px 1px #999;*/
    margin-top: 1rem;
}

.header__company {
    color: white;
    font-size: 16px;
}

.header__period {
    color: white;
    font-size: 16px;
}


form.survey-fields {

}

.survey-fields .form-group,
.survey-fields .form-check {
    margin-bottom: 1.5rem;
}
.survey-fields fieldset .form-check {
    margin-bottom:0;
}

/** Temporary **/
.survey-fields .form-group, .survey .form-check {
    /*border: 2px dashed blue;*/
}
.survey-fields .debug {
    /*font-weight:bold;*/
    /*color:green;*/
    color: gray;
    border: 1px solid gray;
    display:none;
}

.btn.disabled {
    cursor: default !important;
}
.survey {

}

.survey__body {
    padding: 3rem 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-bottom: .2rem solid #f8f9fa;
    border-left: .2rem solid #f8f9fa;
    border-right: .2rem solid #f8f9fa;
}

.survey__message {
    margin-bottom: 6rem;
    /*padding-bottom: 4rem;*/
    /*border-bottom: .2rem solid #f8f9fa;*/
}
.app {
  /*text-align: center;*/
}


@font-face {
  font-family: 'FrutigerLTStd-Roman';
  src: url(/static/media/FrutigerLTStd-Roman.c2831f6c.eot);
  src: local('FrutigerLTStd-Roman'),
  url(/static/media/FrutigerLTStd-Roman.c2831f6c.eot?#iefix) format('embedded-opentype'),
  url(/static/media/FrutigerLTStd-Roman.7bb458fd.woff2) format('woff2'),
  url(/static/media/FrutigerLTStd-Roman.8130b3fa.woff) format('woff'),
  url(/static/media/FrutigerLTStd-Roman.5c798edc.ttf) format('truetype'),
  url(/static/media/FrutigerLTStd-Roman.be316b3e.svg#FrutigerLTStd-Roman) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBlack';
  src: url(/static/media/GothamBlack.cb08c1d8.eot);
  src: local('Gotham Black'), local('GothamBlack'),
  url(/static/media/GothamBlack.cb08c1d8.eot?#iefix) format('embedded-opentype'),
  url(/static/media/GothamBlack.15ab757a.woff2) format('woff2'),
  url(/static/media/GothamBlack.340b137e.woff) format('woff'),
  url(/static/media/GothamBlack.a2297095.ttf) format('truetype'),
  url(/static/media/GothamBlack.ee653a7f.svg#GothamBlack) format('svg');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "FrutigerLT-Roman",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #231f20;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: GothamBlack,sans-serif;
  font-weight:normal;
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Fix for react-datepicker and bootstrap */
.react-datepicker-wrapper {
  display: inline;
  display: initial;
}
.react-datepicker__input-container {
  display: inline;
  display: initial;
}
button:active,button:focus {
  outline:none;
}

.required-indicator {
  font-size: 16px;
  color:red;
}

.ck-editor__editable_inline {
  min-height: 250px;
}
