.header {
    /*background-color: #cccccc;*/
    padding: 1rem 0;

    /*background: #cedce7; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #cedce7 0%, #596a72 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #cedce7 0%,#596a72 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #cedce7 0%,#596a72 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cedce7', endColorstr='#596a72',GradientType=0 ); !* IE6-9 *!*/
    /*background: #0066cc url('../../assets/images/header.png') no-repeat top center fixed cover;*/

    /*background-attachment: fixed;*/
    background-color: rgb(35,31,32);
    text-align: center;
    position: relative;
    z-index: 1;

}

.header:before {
    content: "";
    z-index: -2;
    background-position: top center;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-color: rgb(35,31,32);
    /*background-image: url('../../assets/images/header.png'); moved to styled components*/
    background-size: cover;
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    opacity: 0.5;

}

.header__overlay {
    opacity: 0.5;
    background-color: black;
    width: 100%;
    height: 100%;
    margin: -1rem;
    padding: 0;
    top:0;
    z-index: 0;
    position:absolute;
}

.header__logo {
    max-height: 150px;
    animation: fadein 1s
}

.header__title {
    font-size: 28px;
    color: white;
    /*text-shadow:*/
            /*-1px -1px 1px #999,*/
            /*1px -1px 1px #999,*/
            /*-1px 1px 1px #999,*/
            /*1px 1px 1px #999;*/
    margin-top: 1rem;
}

.header__company {
    color: white;
    font-size: 16px;
}

.header__period {
    color: white;
    font-size: 16px;
}

