@font-face {
  font-family: 'FrutigerLTStd-Roman';
  src: url('assets/fonts/FrutigerLTStd-Roman.eot');
  src: local('FrutigerLTStd-Roman'),
  url('assets/fonts/FrutigerLTStd-Roman.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/FrutigerLTStd-Roman.woff2') format('woff2'),
  url('assets/fonts/FrutigerLTStd-Roman.woff') format('woff'),
  url('assets/fonts/FrutigerLTStd-Roman.ttf') format('truetype'),
  url('assets/fonts/FrutigerLTStd-Roman.svg#FrutigerLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBlack';
  src: url('assets/fonts/GothamBlack.eot');
  src: local('Gotham Black'), local('GothamBlack'),
  url('assets/fonts/GothamBlack.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/GothamBlack.woff2') format('woff2'),
  url('assets/fonts/GothamBlack.woff') format('woff'),
  url('assets/fonts/GothamBlack.ttf') format('truetype'),
  url('assets/fonts/GothamBlack.svg#GothamBlack') format('svg');
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "FrutigerLT-Roman",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #231f20;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: GothamBlack,sans-serif;
  font-weight:normal;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Fix for react-datepicker and bootstrap */
.react-datepicker-wrapper {
  display: initial;
}
.react-datepicker__input-container {
  display: initial;
}
button:active,button:focus {
  outline:none;
}

.required-indicator {
  font-size: 16px;
  color:red;
}

.ck-editor__editable_inline {
  min-height: 250px;
}