.survey {

}

.survey__body {
    padding: 3rem 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-bottom: .2rem solid #f8f9fa;
    border-left: .2rem solid #f8f9fa;
    border-right: .2rem solid #f8f9fa;
}

.survey__message {
    margin-bottom: 6rem;
    /*padding-bottom: 4rem;*/
    /*border-bottom: .2rem solid #f8f9fa;*/
}